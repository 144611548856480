import logo from './logo.svg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Container from '@mui/material/Container'
import React from 'react'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css'

import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'

import LinearProgress from '@mui/material/LinearProgress'

import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'

import axios from 'axios'

import defaultContract from './defaultContract.js'

const mammoth = require('mammoth/mammoth.browser')

axios.defaults.baseURL = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : 'http://localhost:5000'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    }
}

function App() {
    const [editorState, setEditorState] = React.useState('')
    //const [editorState, setEditorState] = React.useState(defaultContract)
    const [isLoading, setIsLoading] = React.useState(false)
    const [analysis, setAnalysis] = React.useState('')
    //const [analysisButtons, setAnalysisButtons] = React.useState([])
    //const [summaryButtons, setSummaryButtons] = React.useState([])
    /*
    const [
        alternativePhraseButtons,
        setAlternativePhraseButtons
    ] = React.useState([])
    */
    const [serverErrors, setServerErrors] = React.useState(0)

    //dialog
    const [openSettings, setOpenSettings] = React.useState(false)

    const [buttons, setButtons] = React.useState([])
    const [firstEdit, setFirstEdit] = React.useState(true)

    const handleCloseSettings = (e, reason) => {
        //prevent backdrop closure
        if (reason && reason == 'backdropClick') {
            return
        }

        //render the buttons
        let clauseButtons = [
            { name: 'Indemnification', type: 'indemnification' },
            { name: 'Alternative Dispute Resolution', type: 'adr' },
            { name: 'Confidentiality', type: 'confidentiality' },
            { name: 'Governing Law', type: 'governingLaw' },
            { name: 'Limitation of Liability', type: 'limitationOfLiability' },
            { name: 'Independent Contractor Status', type: 'icStatus' },
            {
                name: 'Type and methods of information collected',
                type: 'privacyInfoDisclosureStatus'
            },
            {
                name: 'Use of cookies and similar technologies',
                type: 'privacyCookieUse'
            },
            {
                name: 'Children’s Online Privacy Protection Act (COPPA)',
                type: 'privacyCOPPA'
            },
            { name: 'Contact Information', type: 'privacyContactInformation' }
        ]

        let stateClauses = [
            { name: 'Non Compete', type: 'nonCompete' },
            { name: 'Non Solicit', type: 'nonSolicit' }
        ]

        if (state === 'ca') {
            stateClauses = [
                { name: 'Non Compete', type: 'nonCompete_CA' },
                { name: 'Non Solicit', type: 'nonSolicit_CA' }
            ]
        }
        //clauseButtons = clauseButtons.concat(stateClauses)
        clauseButtons = stateClauses.concat(clauseButtons)

        setButtons(clauseButtons)
        setOpenSettings(false)
    }

    const editor = React.useRef(null)

    /*
    React.useEffect(
        () => {
            if (!firstEdit) {
                return
            }
            // on first edit open settings dialog
            setOpenSettings(true)
            setFirstEdit(false)
        },
        [editorState]
    )
    */

    const handleFileUpload = e => {
        if (!e.target.files) {
            return
        }
        const file = e.target.files[0]
        if (!file) {
            return
        }

        var reader = new FileReader()
        reader.onloadend = function(event) {
            var arrayBuffer = reader.result
            // debugger
            //

            let options = {
                ignoreEmptyParagraphs: false
                //styleMap: ["p => p:separator('\n\n\n')"]

                //styleMap: ["p => pre:separator('\n')"]
            }

            mammoth
                .convertToHtml({ arrayBuffer: arrayBuffer }, options)
                .then(function(resultObject) {
                    //result1.innerHTML = resultObject.value

                    console.log(resultObject.value)
                    setEditorState(resultObject.value)
                    //open up dialog
                    setOpenSettings(true)
                    e.target.value = null
                })
            console.timeEnd()
        }
        reader.readAsArrayBuffer(file)
    }

    const onEditorStateChange = eState => {
        /*
        console.log('state')
        console.log(eState)

        console.log(editor)
        console.log('test')
        let tmp = editor.current.getEditor()
        let text = tmp.getText()
        console.log('text output')
        console.log(text)
        */
        setEditorState(eState)
    }

    const renderAnalysisActions = ans => {
        setAnalysis(ans.analysis)
        let doc = editor.current.getEditor()
        let docText = doc.getText()
        let actions = ans.actions
        for (let action of actions) {
            if (action.deleteText) {
                let startIndex = action['startIndex']
                let endIndex = action['endIndex']
                console.log('replace text startindex ' + startIndex)
                console.log(action.deleteText)
                console.log('replace text endIndex' + endIndex)
                doc.deleteText(startIndex, action.deleteText.length)
                doc.insertText(startIndex, action.replaceText, {
                    color: 'green',
                    background: 'yellow'
                })
            }
        }
    }

    const summarize = async clauseType => {
        let tmp2 = editor.current.getEditor()
        //tmp2.deleteText(6, 4)

        if (serverErrors > 5) {
            setAnalysis('Error, try again later.')
            return
        }
        setIsLoading(true)
        setAnalysis('Loading...')
        let tmp = editor.current.getEditor()
        let text = tmp.getText()
        console.log(text)
        let data = { clauseType: clauseType, document: text }
        try {
            let res = await axios.post('/summarize', data)
            let ans = res.data

            console.log('response')
            console.log(ans)
            renderAnalysisActions(ans)
            setServerErrors(0)
        } catch (e) {
            console.log('network error')
            console.log(e)
            setServerErrors(serverErrors + 1)
            summarize(clauseType)
            setAnalysis('Trying, trying, trying. Please be patient.')
        }

        setIsLoading(false)
    }

    const paraphrase = async clauseType => {
        let tmp2 = editor.current.getEditor()
        //tmp2.deleteText(6, 4)
        if (serverErrors > 5) {
            setAnalysis('Error, try again later.')
            return
        }

        setIsLoading(true)
        setAnalysis('Loading...')
        let tmp = editor.current.getEditor()
        let text = tmp.getText()
        console.log(text)
        let data = { clauseType: clauseType, document: text }
        try {
            let res = await axios.post('/paraphrase', data)
            let ans = res.data

            console.log('response')
            console.log(ans)
            renderAnalysisActions(ans)
            setServerErrors(0)
        } catch (e) {
            console.log('network error')
            console.log(e)
            setServerErrors(serverErrors + 1)
            paraphrase(clauseType)
            setAnalysis('Trying, trying, trying. Please be patient.')
        }

        setIsLoading(false)
    }

    const analyze = async clauseType => {
        let tmp2 = editor.current.getEditor()
        //tmp2.deleteText(6, 4)

        if (serverErrors > 5) {
            setAnalysis('Error, try again later.')
            return
        }
        setIsLoading(true)
        setAnalysis('Loading...')
        let tmp = editor.current.getEditor()
        let text = tmp.getText()
        console.log(text)
        let data = { clauseType: clauseType, document: text }
        try {
            let res = await axios.post('/analyze', data)
            let ans = res.data

            console.log('response')
            console.log(ans)
            renderAnalysisActions(ans)
            setServerErrors(0)
        } catch (e) {
            console.log('network error')
            console.log(e)
            setServerErrors(serverErrors + 1)
            analyze(clauseType)
            setAnalysis('Trying, trying, trying. Please be patient.')
        }

        setIsLoading(false)
    }

    /*
    const [value, setValue] = React.useState('1')

    const handleTabChange = (event, newValue) => {
        setValue(newValue)
    }
    */

    const [tabValue, setTabValue] = React.useState(0)

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue)
    }

    // jurisdiction
    const [state, setState] = React.useState('')

    const handleStateChange = event => {
        setState(event.target.value)
    }

    return (
        <Container>
            <br />
            <br />
            <Button
                component="label"
                variant="contained"
                disableElevation
                size="large"
                sx={{ marginRight: '1rem', marginBottom: '2em' }}
            >
                Upload Document
                <input
                    type="file"
                    accept=".docx"
                    hidden
                    onChange={handleFileUpload}
                />
            </Button>
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={openSettings}
                onClose={handleCloseSettings}
                disableEscapeKeyDown
            >
                <DialogTitle>Analysis Setup</DialogTitle>
                <DialogContent>
                    <br />
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                            State
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={state}
                            label="State"
                            onChange={handleStateChange}
                        >
                            <MenuItem value={'ca'}>CA</MenuItem>
                            <MenuItem value={'other'}>Other</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSettings}>Start</Button>
                </DialogActions>
            </Dialog>
            <ReactQuill
                ref={editor}
                theme="snow"
                value={editorState}
                onChange={onEditorStateChange}
                style={{
                    backgroundColor: 'white',
                    height: '70vh',
                    display: 'block',
                    lineHeight: '3em'
                }}
                modules={{
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                        ['blockquote', 'code-block'],

                        [{ header: 1 }, { header: 2 }], // custom button values
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
                        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                        //[{ direction: 'rtl' }], // text direction
                        [{ direction: [] }] // text direction
                        //[{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
                        //[{ header: [1, 2, 3, 4, 5, 6, false] }],

                        //[{ color: [] }, { background: [] }], // dropdown with defaults from theme
                        //[{ align: [] }]
                    ]
                }}
            />
            <br />
            <br />
            <br />
            <br />
            <div>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            aria-label="basic tabs example"
                        >
                            <Tab label="Analysis" {...a11yProps(0)} />
                            <Tab label="Summarization" {...a11yProps(1)} />
                            <Tab label="Alternate Phrasing" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={tabValue} index={0}>
                        <Stack
                            useFlexGap
                            flexWrap="wrap"
                            direction="row"
                            spacing={2}
                        >
                            {buttons.map(clause => (
                                <Button
                                    disableElevation
                                    style={{
                                        minWidth: '35em',
                                        marginRight: '2em'
                                    }}
                                    variant="contained"
                                    onClick={() => analyze(clause.type)}
                                    disabled={isLoading ? true : false}
                                >
                                    {clause.name}
                                </Button>
                            ))}
                        </Stack>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <Stack
                            spacing={2}
                            useFlexGap
                            flexWrap="wrap"
                            direction="row"
                        >
                            {buttons.map(clause => (
                                <Button
                                    disableElevation
                                    style={{
                                        minWidth: '35em',
                                        marginRight: '2em'
                                    }}
                                    variant="contained"
                                    onClick={() => summarize(clause.type)}
                                    disabled={isLoading ? true : false}
                                    color="secondary"
                                >
                                    {clause.name}
                                </Button>
                            ))}
                        </Stack>
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        <Stack
                            useFlexGap
                            flexWrap="wrap"
                            direction="row"
                            spacing={2}
                        >
                            {buttons.map(clause => (
                                <Button
                                    disableElevation
                                    style={{
                                        minWidth: '35em',
                                        marginRight: '2em'
                                    }}
                                    variant="contained"
                                    onClick={() => paraphrase(clause.type)}
                                    disabled={isLoading ? true : false}
                                    color="warning"
                                >
                                    {clause.name}
                                </Button>
                            ))}
                        </Stack>
                    </TabPanel>
                </Box>
            </div>
            {isLoading ? (
                <Stack
                    sx={{ marginTop: '2em', width: '100%', color: 'grey.500' }}
                    spacing={2}
                >
                    <LinearProgress color="secondary" />
                    <LinearProgress color="success" />
                    <LinearProgress color="inherit" />
                </Stack>
            ) : (
                ''
            )}
            {analysis ? (
                <div
                    style={{
                        borderColor: 'lightBlue',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        marginTop: '3em',
                        padding: '1em',
                        color: '#0096FF',
                        fontWeight: 600,
                        backgroundColor: 'white'
                    }}
                >
                    <div
                        style={{
                            fontSize: '1.5em',
                            marginBottom: '1em'
                        }}
                    >
                        Result
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: analysis }} />
                </div>
            ) : (
                ''
            )}
            <br />
            <br />
            <br />
            <br />
        </Container>
    )
}

export default App
